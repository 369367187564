const strings = {
  SOURCE: 'Source',
  DEMO: 'Demo',
  PRIVATE: 'Private',
  UNAVAILABLE: 'Unavailable',
  BROKEN_SENTENCE: "Oh dear, looks like something's broken...",
  FIX: "I'll get right on that!",
};

export default strings;
