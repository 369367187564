import actionTypes from './actionTypes';
import { get404DataRequest, getRandomJoke } from '../repository/ApiRepository';
import { getSiteDataFailure } from './dataActions';

export const get404DataBegin = () => {
  return {
    type: actionTypes.GET_404_DATA_BEGIN,
  };
};
export const get404DataSuccess = (payload) => {
  return {
    type: actionTypes.GET_404_DATA_SUCCESS,
    payload,
  };
};
export const get404DataFailure = (errorReason) => {
  return {
    type: actionTypes.GET_404_DATA_FAILURE,
    errorReason,
  };
};

export const get404Data = () => {
  return (dispatch) => {
    dispatch(get404DataBegin());
    get404DataRequest()
      .then((json) => dispatch(get404DataSuccess(json)))
      .catch((error) => dispatch(getSiteDataFailure(error)));
  };
};

export const get404JokeSuccess = ({ joke }) => {
  return {
    type: actionTypes.GET_RANDOM_JOKE_SUCCESS,
    joke,
  };
};

export const get404Joke = (backupJoke) => {
  return (dispatch) => {
    getRandomJoke()
      .then((json) => dispatch(get404JokeSuccess(json)))
      .catch((error) => {
        console.log(error);
        dispatch(get404JokeSuccess({ joke: backupJoke }));
      });
  };
};
