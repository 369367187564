import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import amber from '@material-ui/core/colors/amber';

const palette = {
  type: 'dark',
  primary: { main: blueGrey[900] },
  secondary: { main: amber[800] },
  background: {
    default: blueGrey[900],
    paper: blueGrey[800],
  },
};

const typography = {
  fontFamily: 'rawline',
  h1: {
    fontWeight: 300,
  },
  h2: {
    fontSize: '4rem',
    fontWeight: 300,
  },
  h4: {
    fontWeight: 200,
  },
  h6: {
    fontWeight: 300,
  },
  body1: {
    fontFamily: 'Nunito',
  },
  body2: {
    fontFamily: 'Nunito',
  },
  button: {
    fontWeight: 700,
    letterSpacing: '0.14em',
  },
  caption: {
    fontWeight: 500,
  },
  overline: {
    fontWeight: 600,
    letterSpacing: '0.25em',
    fontSize: '0.66rem',
  },
};

const shape = {
  borderRadius: 8,
  mediumRadius: 16,
  largeRadius: 26,
};

const overrides = {
  MuiCard: {
    root: {
      borderRadius: shape.mediumRadius,
      boxShadow:
        '-1px -1px 4px rgba(0,0,0,0.16), -1px -1px 4px rgba(0,0,0,0.23)',
    },
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 930,
    lg: 1280,
    xl: 1920,
  },
};

const createAppTheme = () => {
  return createMuiTheme({
    palette,
    typography,
    shape,
    overrides,
    breakpoints,
  });
};

const defaultTheme = createAppTheme();

export default defaultTheme;

export const cardThemeOverlay = (theme) =>
  createMuiTheme({
    ...theme,
    palette: {
      primary: theme.palette.primary,
      secondary: theme.palette.secondary,
      type: 'light',
      background: {
        paper: '#fff',
      },
    },
  });
