import React, { useEffect } from 'react';
import { get404Data, get404Joke } from '../state/notFoundActions';
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import MarkdownTypography from '../util/MarkdownTypography';
import Logo from './Logo';

const AnimatedMarkdownTypography = animated(MarkdownTypography);

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
      paddingTop: '70px',
    },
    paddingTop: '130px',
  },
  errorCode: {
    opacity: 0.02,
    position: 'absolute',
    right: 0,
    bottom: 0,
    fontSize: '40rem',
    lineHeight: '0.9',
    [theme.breakpoints.down('md')]: {
      fontSize: '30rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '10rem',
    },
    overflow: 'hidden',
  },
  homeButton: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
}));

const NotFound404 = () => {
  const data = useSelector((state) => state.notFound.data);
  const joke = useSelector((state) => state.notFound.joke);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get404Data());
    dispatch(get404Joke());
  }, [dispatch]);

  const jokeEnterAnim = useSpring({
    delay: joke.length > 0 ? 2500 : 0,
    opacity: joke.length > 0 ? 1 : 0,
    config: { clamp: true },
  });

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles();
  return (
    <div>
      <AppBar position='static' elevation={0}>
        <Toolbar variant='dense'>
          <Logo />
        </Toolbar>
      </AppBar>
      <Grid container spacing={lgDown ? 5 : 10} className={classes.root}>
        <Typography variant='h1' className={classes.errorCode}>
          404
        </Typography>
        <Grid item xs={12} md={6} />
        <Grid
          container
          direction='column'
          alignItems='center'
          item
          xs={12}
          md={6}
        >
          <Grid item>
            <Typography variant={isXs ? 'h3' : 'h1'}>
              {data.title || ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={isXs ? 'body1' : 'h6'}>
              {data.subtitle || ''}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              size='large'
              href='/'
              className={classes.homeButton}
            >
              {data.returnHomeButton || ''}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction='column'
          alignItems='center'
          item
          xs={12}
          md={6}
        >
          <Grid item>
            <Typography variant={isXs ? 'body1' : 'h6'}>
              {data.jokeSubtitle || ''}
            </Typography>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(4) }}>
            <AnimatedMarkdownTypography
              variant={isXs ? 'body2' : 'subtitle1'}
              align='center'
              source={joke || ''}
              style={jokeEnterAnim}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound404;
