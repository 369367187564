const actionTypes = {
  GET_SITE_DATA_BEGIN: 1,
  GET_SITE_DATA_SUCCESS: 2,
  GET_SITE_DATA_FAILURE: 3,
  CONTACT_FORM_INVALID_NAME: 4,
  CONTACT_FORM_INVALID_EMAIL: 5,
  CONTACT_FORM_INVALID_MESSAGE: 6,
  SENDING_EMAIL: 7,
  SENT_EMAIL: 8,
  SENDING_EMAIL_FAILURE: 9,
  MOBILE_MENU_OPEN_TOGGLE: 10,
  GET_404_DATA_BEGIN: 11,
  GET_404_DATA_SUCCESS: 12,
  GET_404_DATA_FAILURE: 13,
  GET_RANDOM_JOKE_SUCCESS: 14,
};

export default actionTypes;
