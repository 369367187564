import actionTypes from './actionTypes';
import { getSiteDataRequest } from '../repository/ApiRepository';

export const getSiteDataBegin = () => {
  return {
    type: actionTypes.GET_SITE_DATA_BEGIN,
  };
};

export const getSiteDataSuccess = (payload) => {
  return {
    type: actionTypes.GET_SITE_DATA_SUCCESS,
    payload,
  };
};

export const getSiteDataFailure = () => {
  return {
    type: actionTypes.GET_SITE_DATA_FAILURE,
  };
};

export const getSiteData = () => {
  return (dispatch) => {
    dispatch(getSiteDataBegin());
    getSiteDataRequest()
      .then((json) => dispatch(getSiteDataSuccess(json)))
      .catch((error) => {
        console.log(error);
        dispatch(getSiteDataFailure());
      });
  };
};
