import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '24px',
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      height: '44px',
      margin: theme.spacing(2),
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      height: '60px',
      margin: theme.spacing(3),
      marginLeft: '84px',
    },
  },
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1200 737.5'
      className={classes.root}
    >
      <g id='daniel_baxter_logo_top' fill='white'>
        <path d='M 130.1 166.6 c 0 0 0 -0.1 0 -0.1 c 0 -20 16.2 -36.1 36.1 -36.1 l 274.7 0.1 c 0 65.8 0 171.8 0 262.3 l 130.4 -43.2 c 0 -29.4 0 -247.6 0 -282.9 c -3.7 -39 -37.1 -68 -75.8 -66.7 c 3.1 -0.1 6.1 0 9.1 0.3 H 160.7 c 1.8 -0.1 3.7 -0.1 5.5 -0.1 C 74.4 0.3 0 74.8 0 166.6 v 372.3 l 130.1 -43.1 V 166.6 Z' />
        <path d='M 166.3 607.5 c -0.8 0 -1.5 0 -2.3 -0.1 C 164.7 607.4 165.5 607.5 166.3 607.5 L 166.3 607.5 Z' />
        <path d='M 140.8 596.9 c 0 0 -0.1 -0.1 -0.1 -0.1 C 140.7 596.8 140.7 596.9 140.8 596.9 Z' />
        <path d='M 759 287.4 V 131.1 l 274.7 -0.7 c 0 0 0.1 0 0.1 0 c 20 0.1 36.1 16.3 36 36.3 v 17.8 l 128.3 -42.5 c 0.4 3 0.8 6 1.1 9 c -8 -84.5 -79.1 -150.6 -165.6 -150.7 c 1.8 0 3.7 0.1 5.5 0.1 H 703.9 c 1.5 -0.1 2.9 -0.1 4.4 -0.1 c -72.3 0.9 -79.5 47.3 -79.2 66.6 v 263.6' />
        <path d='M 1199.8 159.1 c 0 -0.6 0 -1.1 -0.1 -1.7 C 1199.8 158 1199.8 158.6 1199.8 159.1 Z' />
        <path d='M 1199.9 162.9 c 0 -0.4 0 -0.9 0 -1.3 C 1199.9 162 1199.9 162.4 1199.9 162.9 Z' />
        <polygon points='1200,166.6 1200,166.6 1200,166.6' />
        <path d='M 1199.3 151.5 c 0.1 1.4 0.2 2.8 0.3 4.3 C 1199.5 154.3 1199.4 152.9 1199.3 151.5 Z' />
      </g>
      <g id='daniel_baxter_logo_top_overlay' fill='black' opacity='0.25'>
        <path d='M 130.1 166.6 c 0 0 0 -0.1 0 -0.1 c 0 -20 16.2 -36.1 36.1 -36.1 l 274.7 0.1 c 0 65.8 0 171.8 0 262.3 l 130.4 -43.2 c 0 -29.4 0 -247.6 0 -282.9 c -3.7 -39 -37.1 -68 -75.8 -66.7 c 3.1 -0.1 6.1 0 9.1 0.3 H 160.7 c 1.8 -0.1 3.7 -0.1 5.5 -0.1 C 74.4 0.3 0 74.8 0 166.6 v 372.3 l 130.1 -43.1 V 166.6 Z' />
        <path d='M 166.3 607.5 c -0.8 0 -1.5 0 -2.3 -0.1 C 164.7 607.4 165.5 607.5 166.3 607.5 L 166.3 607.5 Z' />
        <path d='M 140.8 596.9 c 0 0 -0.1 -0.1 -0.1 -0.1 C 140.7 596.8 140.7 596.9 140.8 596.9 Z' />
        <path d='M 759 287.4 V 131.1 l 274.7 -0.7 c 0 0 0.1 0 0.1 0 c 20 0.1 36.1 16.3 36 36.3 v 17.8 l 128.3 -42.5 c 0.4 3 0.8 6 1.1 9 c -8 -84.5 -79.1 -150.6 -165.6 -150.7 c 1.8 0 3.7 0.1 5.5 0.1 H 703.9 c 1.5 -0.1 2.9 -0.1 4.4 -0.1 c -72.3 0.9 -79.5 47.3 -79.2 66.6 v 263.6' />
        <path d='M 1199.8 159.1 c 0 -0.6 0 -1.1 -0.1 -1.7 C 1199.8 158 1199.8 158.6 1199.8 159.1 Z' />
        <path d='M 1199.9 162.9 c 0 -0.4 0 -0.9 0 -1.3 C 1199.9 162 1199.9 162.4 1199.9 162.9 Z' />
        <polygon points='1200,166.6 1200,166.6 1200,166.6' />
        <path d='M 1199.3 151.5 c 0.1 1.4 0.2 2.8 0.3 4.3 C 1199.5 154.3 1199.4 152.9 1199.3 151.5 Z' />
      </g>
      <g id='daniel_baxter_logo_bottom' fill='white'>
        <path d='M 1039.1 0.4 c -1.8 -0.1 -3.7 -0.1 -5.5 -0.1 c 0 0 -85 0 -169.9 0 H 708.4 c -1.5 0 -3 0.1 -4.5 0.1 H 1039.1 Z' />
        <path d='M 759 287.4 l -183.5 60.8 l -4.2 1.5 c 0 0 0 0 0 -0.1 L 441 392.8 c 0 16.7 0 33 0 48.4 v 91.5 L 759 422.5 V 287.4 Z' />
        <path d='M 504.7 0.4 c -4 -0.4 -8.1 -0.5 -12.2 -0.1 H 166.3 c -1.9 0 -3.7 0.1 -5.5 0.1 H 504.7 Z' />
        <path d='M 1069.9 184.4 v 123.2 l -72.3 55.2 l 72.3 53.2 v 155.3 c 0 20 -16.2 36.1 -36.1 36.1 H 166.3 c -19.9 0 -36.1 -16.1 -36.1 -36 v -75.6 L 0 538.9 v 32.5 c 0.2 91.7 74.5 166 166.3 166.1 h 867.5 c 91.7 -0.1 166.1 -74.4 166.3 -166.1 V 166.6 c 0 -8.4 -0.6 -16.6 -1.9 -24.7 L 1069.9 184.4 Z' />
      </g>
    </svg>
  );
};

export default Logo;
