import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import generateStore from '../state/Store';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import Home from './Home';
import NotFound404 from './404.js';
import defaultTheme from './Theme';

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={generateStore()}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route component={NotFound404} />
          </Switch>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
