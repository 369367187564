import React from 'react';
import { makeStyles, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: ({ fullWidth }) => (fullWidth ? theme.spacing(2) : '0px'),
  },
  navItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: ({ fullWidth }) => (fullWidth ? theme.spacing(1) : '0px'),
  },
}));

const NavMenu = ({ fullWidth, sections, onMenuItemClick = () => {} }) => {
  const classes = useStyles({ fullWidth });

  const onItemClick = (anchor) => {
    onMenuItemClick();
    document.querySelector(anchor).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Grid
      container
      direction={fullWidth ? 'column' : 'row'}
      justify='flex-end'
      alignItems='stretch'
      className={classes.root}
    >
      {sections.map((section, i) => {
        return (
          <Grid
            item
            xs={fullWidth ? 12 : 'auto'}
            key={i}
            className={classes.navItem}
          >
            <Button
              variant={section.isHighPriority ? 'outlined' : 'text'}
              color='inherit'
              fullWidth
              onClick={() =>
                onItemClick(`#${section.sectionTitle.toLowerCase()}`)
              }
            >
              {section.sectionTitle}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default NavMenu;
