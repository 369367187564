import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import appReducer from './appReducer';

window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

const generateStore = (initialState) => {
  const store = createStore(
    appReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      window.devToolsExtension ? window.devToolsExtension() : (f) => f
    )
  );
  return store;
};

export default generateStore;
