import React from 'react';
import Section from './Section';
import MarkdownTypography from '../../util/MarkdownTypography';
import {
  Grid,
  makeStyles,
  useTheme,
  Divider,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.only('xl')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  sectionItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sectionText: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    '& img': {
      display: 'block',
      width: '56px',
      margin: 'auto',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    '& ul': {
      textAlign: 'left',
    },
  },
  divider: {
    width: '75%',
    margin: 'auto',
  },
}));

const SectionItem = React.memo(({ text, fullWidth, isSmall, classes }) => {
  const textArray = Array.isArray(text) ? text : [text];
  return (
    <Grid item xs={12} md={fullWidth ? 12 : 6} className={classes.sectionItem}>
      {textArray.map((paragraph, i) => (
        <MarkdownTypography
          variant={isSmall ? 'body1' : 'subtitle1'}
          source={paragraph}
          key={i}
          className={classes.sectionText}
        />
      ))}
    </Grid>
  );
});

const SkillsSection = React.memo(({ data }) => {
  const { technicalSections, softSections } = data;

  const isSmall = useMediaQuery(useTheme().breakpoints.down('sm'));
  const classes = useStyles();
  return (
    <Section data={data}>
      <Grid
        container
        alignItems='stretch'
        spacing={isSmall ? 5 : 10}
        className={classes.root}
      >
        {technicalSections.map(({ text, fullWidth }, i) => (
          <SectionItem
            text={text}
            fullWidth={fullWidth}
            isSmall={isSmall}
            classes={classes}
            key={i}
          />
        ))}
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        {softSections.map(({ text, fullWidth }, i) => (
          <SectionItem
            text={text}
            fullWidth={fullWidth}
            isSmall={isSmall}
            classes={classes}
            key={i}
          />
        ))}
      </Grid>
    </Section>
  );
});

export default SkillsSection;
