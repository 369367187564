import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSiteData } from '../state/dataActions';
import NavBar from './TopNavBar';
import {
  Grid,
  Hidden,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import NavMenu from './NavMenu';
import Content from './Content';
import Footer from './Footer';
import Error from './Error';

const useStyles = makeStyles((theme) => ({
  root: {
    height: ' calc(var(--vh, 1vh) * 100)',
    width: '100vw',
    flexWrap: 'noWrap',
  },
  contentContainer: {
    flexGrow: 1,
    flexWrap: 'noWrap',
    minHeight: 0,
  },
  navContainer: {
    width: (theme.breakpoints.values.xl / 12) * 2,
  },
  nav: {
    marginTop: theme.spacing(10),
    flexWrap: 'noWrap',
  },
  overflow: {
    flexWrap: 'noWrap',
  },
  center: {
    alignSelf: 'center',
    margin: 'auto',
  },
}));

const Home = () => {
  const classes = useStyles();

  const isLoading = useSelector((state) => state.data.isLoading);
  const sections = useSelector((state) => state.data.sections);
  const isError = useSelector((state) => state.data.isError);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSiteData());
  }, [dispatch]);

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item>
        <NavBar />
      </Grid>
      <Grid
        container
        item
        alignItems='stretch'
        className={classes.contentContainer}
      >
        {isLoading && (
          <CircularProgress color='inherit' className={classes.center} />
        )}
        {isError && <Error />}
        {!isLoading && !isError && (
          <Fragment>
            <Hidden mdDown>
              <Grid
                container
                item
                direction='column'
                className={classes.navContainer}
              >
                <Grid item className={classes.nav}>
                  <NavMenu fullWidth={true} sections={sections} />
                </Grid>
                <Grid item style={{ flexGrow: 1 }} />
                <Grid item>
                  <Footer
                    links={
                      sections.length > 0
                        ? sections.find((section) => section.id === 'CONTACT')
                            .links
                        : []
                    }
                  />
                </Grid>
              </Grid>
            </Hidden>
            <Grid
              container
              item
              xs
              direction='row'
              className={classes.overflow}
            >
              <Content sections={sections} />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};
export default Home;
