import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { ContentPaddingContext } from '../Content';

const useStyles = makeStyles((theme) => ({
  shift: {
    paddingLeft: ({ padding }) => theme.spacing(padding),
    paddingRight: ({ padding }) => theme.spacing(padding),
    boxSizing: 'border-box',
  },
  subheaderContainer: {
    display: 'inline-block',
  },
  subheader: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  underline: {
    opacity: 0.3,
    margin: '0px',
  },
  sectionContent: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
}));

// when found out how to make min height 100% again use prop to set css
const Section = ({
  fullHeight = false,
  data: { id, sectionTitle, invisibleSectionTitle },
  children,
}) => {
  const [padding] = useContext(ContentPaddingContext);

  const classes = useStyles({ padding });
  return (
    <Grid container direction='column' id={id.toLowerCase()}>
      {!invisibleSectionTitle && (
        <Grid item>
          <div className={classes.subheaderContainer}>
            <Typography
              variant='h5'
              className={clsx(classes.shift, classes.subheader)}
            >
              {sectionTitle}
            </Typography>
            <hr align='left' className={classes.underline} />
          </div>
        </Grid>
      )}
      <Grid item className={clsx(classes.sectionContent, classes.shift)}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Section;
