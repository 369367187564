import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import strings from '../util/Strings';

const useStyles = makeStyles({
  center: {
    alignSelf: 'center',
    margin: 'auto',
  },
});

const Error = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      spacing={4}
      item
      className={classes.center}
    >
      <Grid item>
        <Typography variant='subtitle1'>
          {strings['BROKEN_SENTENCE']}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h3'>(╯°□°)╯︵ ┻━┻</Typography>
      </Grid>
      <Grid item>
        <Typography variant='subtitle1'>{strings['FIX']}</Typography>
      </Grid>
    </Grid>
  );
};

export default Error;
