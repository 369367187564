import actionTypes from './actionTypes';

const initialState = {
  isLoading: false,
  data: {},
  errorReason: null,
  joke: '',
};

const NotFoundReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_404_DATA_BEGIN:
      return { ...state, isLoading: true };
    case actionTypes.GET_404_DATA_SUCCESS:
      return { ...state, data: action.payload };
    case actionTypes.GET_404_DATA_FAILURE:
      return { ...state, errorReason: action.errorReason };
    case actionTypes.GET_RANDOM_JOKE_SUCCESS:
      return { ...state, joke: action.joke };
    default:
      return state;
  }
};

export default NotFoundReducer;
