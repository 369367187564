import React, { createContext } from 'react';
import { makeStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import AboutSection from './content_sections/AboutSection';
import SkillsSection from './content_sections/SkillsSection';
import ProjectsSection from './content_sections/ProjectsSection';
import ContactSection from './content_sections/ContactSection';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';

const AnimatedGrid = animated(Grid);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: `calc(100% - ${theme.spacing(1)}px)`,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
    },
    background: theme.palette.background.paper,
    borderTopLeftRadius: ({ topLeftBorderRadius }) =>
      theme.spacing(topLeftBorderRadius),
    boxShadow: '-3px -3px 6px rgba(0,0,0,0.16), -3px -3px 6px rgba(0,0,0,0.23)',
    overflow: 'hidden',
    flexWrap: 'noWrap',
    zIndex: '100',
    [theme.breakpoints.down('sm')]: {
      pointerEvents: ({ isMenuOpen }) => (isMenuOpen ? 'none' : 'auto'),
    },
    overflowY: 'scroll',
  },
}));

export const ContentPaddingContext = createContext();

const ContentPaddingProvider = ({ padding, children }) => {
  return (
    <ContentPaddingContext.Provider value={[padding]}>
      {children}
    </ContentPaddingContext.Provider>
  );
};

const Content = ({ sections }) => {
  const isMenuOpen = useSelector((state) => state.mobileMenu.isMenuOpen);

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const backdropOpenAnim = useSpring({
    opacity: isMenuOpen && isSmall ? 0.3 : 1,
    config: { clamp: true },
  });

  let topLeftBorderRadius = 0;
  if (useMediaQuery(theme.breakpoints.up('sm'))) {
    topLeftBorderRadius = 7;
  } else {
    topLeftBorderRadius = 4;
  }

  const classes = useStyles({ topLeftBorderRadius, isMenuOpen });
  const generateSection = (section) => {
    switch (section.id) {
      case 'ABOUT':
        return <AboutSection data={section} />;
      case 'SKILLS':
        return <SkillsSection data={section} />;
      case 'PROJECTS':
        return <ProjectsSection data={section} />;
      case 'CONTACT':
        return <ContactSection data={section} />;
      default:
        throw Error('Invalid Section ID');
    }
  };

  return (
    <ContentPaddingProvider padding={topLeftBorderRadius}>
      <Grid
        container
        item
        xs
        direction='column'
        alignItems='stretch'
        className={classes.root}
      >
        {sections.map((section) => (
          <AnimatedGrid
            container
            item
            xs
            key={section.id}
            style={{ ...backdropOpenAnim, flex: 'none' }}
          >
            {generateSection(section)}
          </AnimatedGrid>
        ))}
      </Grid>
    </ContentPaddingProvider>
  );
};

export default Content;
