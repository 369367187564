import React, { useState, useEffect } from 'react';
import { Card, makeStyles } from '@material-ui/core';
import _uniqueId from 'lodash.uniqueid';

const useStyles = makeStyles((theme) => ({
  card: {
    paddingBottom: theme.spacing(3),
  },
  action: {
    float: 'right',
    transform: ({ actionOffsetX, actionOffsetY }) =>
      `translate(-${actionOffsetX}px, -${actionOffsetY}px)`,
    visibility: ({ actionOffsetX, actionOffsetY }) =>
      actionOffsetX === 0 || actionOffsetY === 0 ? 'hidden' : 'visible',
  },
}));

const ActionCard = (props) => {
  const { action, children, className } = props;

  const [actionId] = useState(_uniqueId('action_card_'));

  const [actionOffsetX, setActionOffsetX] = useState(0);
  const [actionOffsetY, setActionOffsetY] = useState(0);

  useEffect(() => {
    const elem = document.getElementById(actionId);
    setActionOffsetX(elem.scrollWidth / 2);
    setActionOffsetY(elem.scrollHeight / 2);
  }, [actionId]);

  const classes = useStyles({ actionOffsetX, actionOffsetY });
  return (
    <div className={className}>
      <Card className={classes.card} {...props}>
        {children}
      </Card>
      <div id={actionId} className={classes.action}>
        {action}
      </div>
    </div>
  );
};

export default ActionCard;
