import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Divider,
  IconButton,
  Grid,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import { fromRef } from '../util/Icons';

const useStyles = makeStyles((theme) => ({
  links: {
    marginBottom: theme.spacing(4),
  },
}));

const Footer = ({ links }) => {
  const classes = useStyles();

  const [year, setYear] = useState('');
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <Grid container>
      <Grid container item justify='center' className={classes.links}>
        {links.map((link, i) => {
          return (
            <Fragment key={`Footer_Links_Item_${i}`}>
              <Grid item xs={3} align='center'>
                <IconButton href={link.url}>
                  {link.iconPath && (
                    <SvgIcon>
                      <path d={link.iconPath} />
                    </SvgIcon>
                  )}
                  {link.iconRef && fromRef(link.iconRef)}
                </IconButton>
              </Grid>
              {i + 1 <= links.length - 1 && i % 3 !== 2 && (
                <Divider flexItem orientation='vertical' />
              )}
            </Fragment>
          );
        })}
      </Grid>
      <Grid item xs={12} align='center'>
        <Typography variant='overline' color='textSecondary'>
          Copyright © {year} Daniel Baxter
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
