import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import {
  Toolbar,
  IconButton,
  makeStyles,
  Hidden,
  Collapse,
  Grid,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Logo from './Logo';
import NavMenu from './NavMenu';
import AndroidBot from './AndroidBot';
import { mobileMenuOpenToggle } from '../state/mobileMenuActions';

const useStyles = makeStyles((theme) => ({
  noHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  botContainer: {
    alignSelf: 'flex-end',
    marginRight: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(180deg)',
      marginRight: 0,
      marginLeft: theme.spacing(10),
    },
  },
}));

const TopNavBar = () => {
  const classes = useStyles();

  const isLoading = useSelector((state) => state.data.isLoading);
  const sections = useSelector((state) => state.data.sections);
  const isError = useSelector((state) => state.data.isError);

  const isMenuOpen = useSelector((state) => state.mobileMenu.isMenuOpen);

  const dispatch = useDispatch();
  const toggleMenuOpen = () => {
    dispatch(mobileMenuOpenToggle());
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position='static' elevation={0}>
          <Toolbar variant='dense'>
            <Grid container alignItems='center'>
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={toggleMenuOpen}
                    disabled={isLoading || isError}
                    className={classes.noHover}
                    disableRipple
                    aria-label='menu'
                  >
                    {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Grid>
              </Hidden>
              <Logo />
              <Grid item xs />
              <Grid item className={classes.botContainer}>
                {!isLoading && !isError && <AndroidBot />}
              </Grid>
              <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                <Grid item>
                  <NavMenu sections={sections} />
                </Grid>
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Collapse in={isMenuOpen} style={{ willChange: 'height' }}>
            <NavMenu
              fullWidth={true}
              sections={sections}
              onMenuItemClick={toggleMenuOpen}
            />
          </Collapse>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default TopNavBar;
