import React from 'react';
import Section from './Section';
import {
  CardContent,
  Card,
  Typography,
  CardActions,
  Avatar,
  Button,
  Grid,
  CardHeader,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import { GooglePlayIcon, DemoIcon } from '../../util/Icons';
import { cardThemeOverlay } from '../Theme';
import MarkdownTypography from '../../util/MarkdownTypography';
import strings from '../../util/Strings';
import ResponsiveCarousel from '../../util/responsive_carousel/ResponsiveCarousel';

const useStyles = makeStyles((theme) => ({
  projects: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  shortDescription: {
    display: 'inline-block',
    marginBottom: theme.spacing(1.5),
    fontWeight: 700,
  },
  projectContentWrapper: {
    height: '100%',
  },
  prjectItemDescription: {
    flexGrow: 1,
  },
}));

const ProjectItem = React.memo(
  ({
    item: {
      id,
      title,
      subtitle,
      shortDescription,
      logoPath,
      imagePaths,
      description,
      type,
      sourceUrl,
      demoUrl,
      hasDemo = true,
    },
  }) => {
    let demoIcon;
    switch (type) {
      case 'ANDROID':
        demoIcon = <GooglePlayIcon />;
        break;
      default:
        demoIcon = <DemoIcon />;
        break;
    }

    console.log(`project ${id} hasDemo: ${hasDemo}`)

    const arrayDescription = Array.isArray(description)
      ? description
      : [description];

    const displayImagePaths = imagePaths || ['placeholder.svg'];

    const classes = useStyles();
    return (
      <Card id={`project-item-${id}`} className={classes.projectContentWrapper}>
        <Grid
          container
          direction='column'
          alignItems='stretch'
          className={classes.projectContentWrapper}
        >
          <Grid item>
            <CardHeader
              avatar={
                // Try and fix alignment issues with no-logo avatar (it's something with the font ffs)
                logoPath ? (
                  <Avatar
                    src={`/assets/${logoPath}`}
                    aria-label='project-logo'
                  />
                ) : (
                  <Avatar aria-label='project-logo'>{title[0]}</Avatar>
                )
              }
              title={title}
              subheader={subtitle}
              titleTypographyProps={{
                variant: 'subtitle1',
              }}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <ResponsiveCarousel
              imagePaths={displayImagePaths.map((path) => `/assets/${path}`)}
              height={300}
            />
          </Grid>
          <Grid item className={classes.prjectItemDescription}>
            <CardContent align='left'>
              <Typography
                variant='overline'
                color='textSecondary'
                className={classes.shortDescription}
              >
                {shortDescription}
              </Typography>
              {arrayDescription.map((paragraph, i) => (
                <MarkdownTypography
                  variant='body1'
                  color='textSecondary'
                  source={paragraph}
                  key={`project-${id}-desc-${i}`}
                  gutterBottom
                />
              ))}
            </CardContent>
          </Grid>
          <Grid item>
            <CardActions>
              <Button
                startIcon={<CodeRoundedIcon />}
                size='small'
                color='primary'
                href={sourceUrl}
                disabled={!sourceUrl}
              >
                {strings[sourceUrl ? 'SOURCE' : 'PRIVATE']}
              </Button>
              {
                !hasDemo || (
                  <Button
                  startIcon={demoIcon}
                  size='small'
                  color='primary'
                  href={demoUrl}
                  disabled={!demoUrl}>
                    {`${strings['DEMO']}${
                      demoUrl ? '' : ` ${strings['UNAVAILABLE']}`
                    }`}
                  </Button>
                )
              }
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    );
  }
);

const ProjectsSection = React.memo(({ data }) => {
  const { projects } = data;

  const classes = useStyles();
  return (
    <Section fullHeight data={data}>
      <ThemeProvider theme={cardThemeOverlay}>
        <Grid
          container
          alignItems='stretch'
          spacing={6}
          className={classes.projects}
        >
          {projects.map((project, i) => (
            <Grid item xs={12} md={6} xl={4} key={`project-${i}`}>
              <ProjectItem item={project} />
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
    </Section>
  );
});

export default ProjectsSection;
