import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
  markdown: {
    '& p': {
      margin: 0,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
}));

const MarkdownTypography = (props) => {
  const classes = useStyles();
  return (
    <Typography component={'div'} {...props}>
      <ReactMarkdown className={classes.markdown} source={props.source} />
    </Typography>
  );
};

export default MarkdownTypography;
