import React from 'react';
import Section from './Section';
import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MarkdownTypography from '../../util/MarkdownTypography';

const useStyles = makeStyles((theme) => ({
  aboutRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(6),
    },
    paddingBottom: theme.spacing(4),
  },
  subtitleItem: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '250px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  descriptionItem: {
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      minHeight: '550px',
    },
    [theme.breakpoints.only('xs')]: {
      minHeight: '250px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  alignEnd: {
    textAlign: 'end',
  },
  alignCenter: {
    textAlign: 'center',
  },
}));

const AboutSection = React.memo(({ data }) => {
  const { titleOne, titleTwo, subtitle, description } = data;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  return (
    <Section data={data}>
      <Grid container alignItems='flex-end' className={classes.aboutRoot}>
        <Grid container direction='column' item xs={12} sm={6}>
          <Grid item>
            <Typography variant={isSmall ? 'h2' : 'h1'}>{titleOne}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={isSmall ? 'h2' : 'h1'}>{titleTwo}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.subtitleItem}>
          <MarkdownTypography
            variant='h5'
            source={subtitle}
            className={
              useMediaQuery(theme.breakpoints.down('xs'))
                ? classes.alignEnd
                : classes.alignCenter
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.descriptionItem}>
          <MarkdownTypography
            variant='h6'
            source={description}
            className={classes.alignCenter}
          />
        </Grid>
      </Grid>
    </Section>
  );
});

export default AboutSection;
