import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  icon: {
    width: '73px',
    height: '55px',
    animationTimingFunction: 'steps(270)',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(android_bot_60fps.svg)',
    animationDelay: '10s',
    animationIterationCount: 'infinite',
    animationDuration: '60000ms',
  },
  play: {
    animationName: '$playAndroidBot',
  },
  '@keyframes playAndroidBot': {
    '0%': {
      backgroundPosition: '-0px, 0px',
    },
    '7.5%': {
      backgroundPosition: '-19710px, 0px',
    },
    '100%': {
      backgroundPosition: '-19710px, 0px',
    },
  },
});

const AndroidBot = () => {
  const classes = useStyles();
  return <div className={clsx(classes.icon, classes.play)} />;
};

export default AndroidBot;
