import axios from 'axios';

const makeRequest = ({ method = 'get', url, data = {} }) => {
  return axios({ method, url, data }).then((response) => response.data);
};

export const getSiteDataRequest = async () => {
  return makeRequest({
    method: 'get',
    url: '/api/sections',
  });
};

export const get404DataRequest = async () => {
  return makeRequest({
    method: 'get',
    url: '/api/404',
  });
};

export const postContactRequest = async (body) => {
  return makeRequest({
    method: 'post',
    url: '/api/contact-request',
    data: body,
  });
};

export const getRandomJoke = async () => {
  return makeRequest({
    method: 'get',
    url: '/api/randomjoke',
  });
};
