import actionTypes from './actionTypes';
import { postContactRequest } from '../repository/ApiRepository';

export const nameValidationFailed = () => {
  return {
    type: actionTypes.CONTACT_FORM_INVALID_NAME,
  };
};

export const emailValidationFailed = () => {
  return {
    type: actionTypes.CONTACT_FORM_INVALID_EMAIL,
  };
};

export const messageValidationFailed = () => {
  return {
    type: actionTypes.CONTACT_FORM_INVALID_MESSAGE,
  };
};

export const sendingEmail = () => {
  return {
    type: actionTypes.SENDING_EMAIL,
  };
};

export const sentEmail = () => {
  return {
    type: actionTypes.SENT_EMAIL,
  };
};

export const sendingEmailFailure = (error) => {
  return {
    type: actionTypes.SENDING_EMAIL_FAILURE,
    reason: error,
  };
};

export const sendContactRequest = ({ name, email, message }) => {
  console.log(`name: ${name}, email: ${email}, message: ${message}`);
  return (dispatch) => {
    if (name.length === 0) {
      dispatch(nameValidationFailed());
      return;
    } else if (
      email.length === 0 ||
      !RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
      ).test(email)
    ) {
      dispatch(emailValidationFailed());
      return;
    } else if (message.length === 0) {
      dispatch(messageValidationFailed());
      return;
    }
    postContactRequest({
      name,
      email,
      message,
    })
      .then(() => dispatch(sentEmail()))
      .catch((error) => {
        console.log(error);
        dispatch(sendingEmailFailure(error));
      });
  };
};
