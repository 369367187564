import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import {
  makeStyles,
  IconButton,
  Backdrop,
  ThemeProvider,
} from '@material-ui/core';
import defaultTheme from '../../ui/Theme';
import Dots from 'material-ui-dots';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import FullscreenExitRoundedIcon from '@material-ui/icons/FullscreenExitRounded';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles((theme) => ({
  fullscreenBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'rgba(0, 0, 0, 0.9)',
  },
  carouselWrapper: {
    position: 'relative',
    paddingBottom: ({ isFullScreen }) => (isFullScreen ? 0 : '22px'),
  },
  carousel: {
    width: '100%',
    '& .carousel .slide': {
      background: 'transparent',
    },
  },
  slide: {
    width: '100%',
  },
  image: {
    height: ({ height, isFullScreen }) =>
      isFullScreen ? 'auto' : `${height}px`,
    maxHeight: ({ isFullScreen }) => (isFullScreen ? '95vh' : 'auto'),
    maxWidth: '70%',
    width: 'auto !important',
    transition: 'opacity .35s ease-in-out',
  },
  navButton: {
    display: ({ multiItem }) => (multiItem ? 'auto' : 'none'),
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '10',
  },
  prevNavButton: {
    left: 0,
    marginLeft: theme.spacing(2),
  },
  nextNavButton: {
    right: 0,
    marginRight: theme.spacing(2),
  },
  fullScreenButton: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  dots: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  dotsInner: {
    padding: '10px 0 12px',
  },
  dot: {
    background: 'currentColor',
    transition: 'all 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
}));

const NavArrow = ({ clickHandler, isPrev, classes }) => {
  return (
    <IconButton
      onClick={clickHandler}
      className={clsx(
        classes.navButton,
        isPrev ? classes.prevNavButton : classes.nextNavButton
      )}
    >
      {isPrev && <NavigateBeforeRoundedIcon />}
      {!isPrev && <NavigateNextRoundedIcon />}
    </IconButton>
  );
};

const ContentCarousel = ({
  imagePaths = [],
  height,
  currentIndex,
  setCurrentIndex,
  isFullScreen,
  setFullScreen,
  lazyLoadedSlides,
}) => {
  const toggleFullScreen = () => setFullScreen(!isFullScreen);

  const multiItem = imagePaths.length > 1;
  const classes = useStyles({ multiItem, height, isFullScreen });
  return (
    <div className={classes.carouselWrapper}>
      <Carousel
        selectedItem={currentIndex}
        onChange={setCurrentIndex}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={multiItem}
        swipeable={multiItem}
        infiniteLoop
        useKeyboardArrows={multiItem}
        swipeScrollTolerance={80}
        renderArrowPrev={(clickHandler, _hasPrev, _label) => (
          <NavArrow
            clickHandler={clickHandler}
            isPrev={true}
            classes={classes}
          />
        )}
        renderArrowNext={(clickHandler, _hasNext, _label) => (
          <NavArrow
            clickHandler={clickHandler}
            isPrev={false}
            classes={classes}
          />
        )}
        className={classes.carousel}
      >
        {imagePaths.map((path, i) => (
          <div key={i} className={classes.slide}>
            <img
              src={lazyLoadedSlides[i] ? path : ''}
              className={classes.image}
              style={{ opacity: lazyLoadedSlides[i] ? 1 : 0 }}
              alt={lazyLoadedSlides[i] ? path : ''}
            />
          </div>
        ))}
      </Carousel>
      {multiItem && (
        <Fragment>
          <IconButton
            onClick={toggleFullScreen}
            className={classes.fullScreenButton}
          >
            {isFullScreen ? (
              <FullscreenExitRoundedIcon />
            ) : (
              <FullscreenRoundedIcon />
            )}
          </IconButton>
          <Dots
            index={currentIndex}
            count={imagePaths.length}
            onDotClick={setCurrentIndex}
            className={classes.dots}
            classes={{ dots: classes.dotsInner, dot: classes.dot }}
          />
        </Fragment>
      )}
    </div>
  );
};

const ResponsiveCarousel = (props) => {
  const { imagePaths } = props;

  const initLazyLoaded = imagePaths.map((_) => false);
  initLazyLoaded[0] = true;
  const [lazyLoadedSlides, setLazyLoadedSlides] = useState(initLazyLoaded);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullScreen, setFullScreen] = useState(false);

  const updateIndex = (index) => {
    setLazyLoadedSlides(
      lazyLoadedSlides.map((isLoaded, i) => (i === index ? true : isLoaded))
    );
    setCurrentIndex(index);
  };

  useEffect(() => {
    const listener = (e) => {
      if (isFullScreen && e.code === 'Escape') {
        setFullScreen(false);
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [isFullScreen]);

  const classes = useStyles();
  return (
    <Fragment>
      <ContentCarousel
        {...props}
        currentIndex={currentIndex}
        setCurrentIndex={updateIndex}
        isFullScreen={false}
        setFullScreen={setFullScreen}
        lazyLoadedSlides={lazyLoadedSlides}
      />
      <Backdrop open={isFullScreen} className={classes.fullscreenBackdrop}>
        <ThemeProvider theme={defaultTheme}>
          <ContentCarousel
            {...props}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            isFullScreen={isFullScreen}
            setFullScreen={setFullScreen}
            lazyLoadedSlides={lazyLoadedSlides}
          />
        </ThemeProvider>
      </Backdrop>
    </Fragment>
  );
};

export default ResponsiveCarousel;
