import React, { useState, useRef } from 'react';
import Section from './Section';
import Footer from '../Footer';
import {
  Typography,
  makeStyles,
  Grid,
  CardContent,
  TextField,
  ThemeProvider,
  Fab,
  SvgIcon,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/SendRounded';
import ActionCard from '../ActionCard';
import { sendContactRequest } from '../../state/contactActions';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated, useChain } from 'react-spring';
import { cardThemeOverlay } from '../Theme';

const useStyles = makeStyles((theme) => ({
  callToAction: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '300px',
    },
    textAlign: 'center',
  },
  contactCardItem: {
    alignSelf: 'stretch',
    position: 'relative',
  },
  contactCardContainer: {
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    maxWidth: '700px',
    margin: 'auto',
  },
  sendMessage: {
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
  },
  confirmLayout: {
    position: 'absolute',
    width: '50%',
    maxWidth: '500px',
    top: '40%',
    transform: 'translateY(-50%)',
    left: 0,
    right: 0,
    margin: 'auto',
    opacity: 0.6,
  },
  footerItem: {
    visibility: 'visible',
    [theme.breakpoints.up('lg')]: {
      visibility: 'hidden',
    },
    paddingBottom: '0px !important',
  },
}));

const ContactForm = ({ formState, setFormState, genericFailureMessage }) => {
  const nameError = useSelector((state) => state.contact.nameError);
  const emailError = useSelector((state) => state.contact.emailError);
  const messageError = useSelector((state) => state.contact.messageError);
  const emailSendingError = useSelector(
    (state) => state.contact.emailSendingError
  );

  const updateFormState = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            error={nameError}
            fullWidth
            size='small'
            name='name'
            label='Name'
            autoComplete='name'
            onChange={updateFormState}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            error={emailError}
            fullWidth
            size='small'
            name='email'
            label='Email'
            autoComplete='email'
            onChange={updateFormState}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            error={messageError}
            multiline
            fullWidth
            size='small'
            rows={11}
            name='message'
            label='Message'
            onChange={updateFormState}
          />
        </Grid>
        {emailSendingError && (
          <Grid item xs={12}>
            <Typography variant='body2' color='error' align='center'>
              {genericFailureMessage}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const AnimatedFab = animated(Fab);

const ContactSection = React.memo(({ data }) => {
  const {
    callToAction,
    contactConfirmation,
    links,
    sendContactFailureMsg,
  } = data;

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const isSending = useSelector((state) => state.contact.isSending);
  const sent = useSelector((state) => state.contact.sent);

  const dispatch = useDispatch();

  const submitContactRequest = () => {
    if (isSending) {
      return;
    }
    const { name, email, message } = formState;
    dispatch(sendContactRequest({ name, email, message }));
  };

  const disableFabRef = useRef();
  const disableFabAnim = useSpring({
    from: {
      transform: 'scale(1)',
    },
    transform: `scale(${sent ? '0' : '1'})`,
    ref: disableFabRef,
    config: { mass: 1, tension: 500, friction: 40, clamp: true },
  });

  const moveFormRef = useRef();
  const moveFormAnim = useSpring({
    from: { transform: 'translateX(0%)' },
    transform: `translateX(${sent ? `calc(150%)` : '0%'})`,
    ref: moveFormRef,
    config: { mass: 12, tension: 180, friction: 12, clamp: true },
  });

  useChain([
    { current: disableFabRef.current },
    { current: moveFormRef.current },
  ]);

  const classes = useStyles();
  return (
    <Section data={data}>
      <Grid container direction='column' alignItems='center' spacing={6}>
        <Grid item>
          <Typography variant='subtitle1' className={classes.callToAction}>
            {callToAction}
          </Typography>
        </Grid>
        <Grid item className={classes.contactCardItem}>
          <div className={classes.confirmLayout}>
            <SvgIcon style={{ width: '100%', height: '48px' }}>
              <path d='M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z' />
            </SvgIcon>
            <Typography variant='body1' align='center'>
              {contactConfirmation}
            </Typography>
          </div>
          <animated.div style={moveFormAnim}>
            <div className={classes.contactCardContainer}>
              <ThemeProvider theme={cardThemeOverlay}>
                <ActionCard
                  action={
                    <AnimatedFab
                      color='secondary'
                      className={classes.sendMessage}
                      style={disableFabAnim}
                      onClick={submitContactRequest}
                    >
                      <SendIcon style={{ color: 'white' }} />
                    </AnimatedFab>
                  }
                >
                  <CardContent>
                    <ContactForm
                      formState={formState}
                      setFormState={setFormState}
                      genericFailureMessage={sendContactFailureMsg}
                    />
                  </CardContent>
                </ActionCard>
              </ThemeProvider>
            </div>
          </animated.div>
        </Grid>
        <Grid item className={classes.footerItem}>
          <Footer links={links} />
        </Grid>
      </Grid>
    </Section>
  );
});

export default ContactSection;
