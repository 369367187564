import dataReducer from './dataReducer';
import contactReducer from './contactReducer';
import mobileMenuReducer from './mobileMenuReducer';
import notFoundReducer from './notFoundReducer';
import { combineReducers } from 'redux';

const appReducer = combineReducers({
  data: dataReducer,
  contact: contactReducer,
  mobileMenu: mobileMenuReducer,
  notFound: notFoundReducer,
});

export default appReducer;
