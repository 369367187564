import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BitbucketIcon = () => {
  return (
    <SvgIcon viewBox='0 0 32 32'>
      <defs>
        <linearGradient
          x1='100%'
          x2='45.339%'
          y1='29.23%'
          y2='75.038%'
          id='icon-bitbucket-gradient'
        >
          <stop stopColor='currentColor' stopOpacity='0.4' offset='0%'></stop>
          <stop stopColor='currentColor' offset='100%'></stop>
        </linearGradient>
      </defs>
      <path d='M4.78580435,5 C4.55423538,4.99701333 4.33319771,5.09657765 4.18198458,5.27198488 C4.03077145,5.44739211 3.96486141,5.68068714 4.00193478,5.9092887 L7.32946109,26.1096074 C7.3703589,26.355373 7.49665951,26.578828 7.68612174,26.7406224 C7.87680866,26.9055104 8.11992598,26.9972003 8.37200761,26.9992993 L14.5488998,19.5995707 L13.6827239,19.5995707 L12.3227102,12.3958093 L27.3886833,12.3958093 L28.4469072,5.91712739 C28.4862006,5.68935393 28.4229655,5.45584955 28.2741046,5.27903 C28.1252437,5.10221045 27.9059335,5.00010264 27.6747957,5 L4.78580435,5 Z'></path>
      <path
        fill='url(#icon-bitbucket-gradient)'
        d='M27.3886833,12.3958093 L20.0320674,12.3958093 L18.7974728,19.5995707 L13.7023207,19.5995707 L7.68612174,26.7445417 C7.87680866,26.9094297 8.11992598,27.0011197 8.37200761,27.0032187 L24.3394307,27.0032187 C24.727754,27.0082167 25.0611955,26.7281258 25.1233002,26.3447683 L27.3886833,12.3958093 Z'
      ></path>
    </SvgIcon>
  );
};

export const GooglePlayIcon = () => {
  return (
    <SvgIcon>
      <path d='M 5.4160156 2.328125 L 12.935547 10.158203 C 13.132547 10.363203 13.45925 10.363203 13.65625 10.158203 L 15.179688 8.5742188 C 15.405688 8.3392188 15.354312 7.956875 15.070312 7.796875 C 11.137313 5.571875 6.2620156 2.811125 5.4160156 2.328125 z M 3.140625 2.8476562 C 3.055625 3.0456562 3 3.2629063 3 3.5039062 L 3 20.591797 C 3 20.788797 3.044375 20.970625 3.109375 21.140625 L 11.576172 12.324219 C 11.762172 12.131219 11.762172 11.826813 11.576172 11.632812 L 3.140625 2.8476562 z M 17.443359 9.2578125 C 17.335484 9.2729375 17.233297 9.32375 17.154297 9.40625 L 15.015625 11.632812 C 14.829625 11.825812 14.829625 12.130219 15.015625 12.324219 L 17.134766 14.529297 C 17.292766 14.694297 17.546141 14.729188 17.744141 14.617188 C 19.227141 13.777188 20.226563 13.212891 20.226562 13.212891 C 20.725562 12.909891 21.007 12.443547 21 11.935547 C 20.992 11.439547 20.702609 10.981938 20.224609 10.710938 C 20.163609 10.676937 19.187672 10.124359 17.763672 9.3183594 C 17.664172 9.2623594 17.551234 9.2426875 17.443359 9.2578125 z M 13.296875 13.644531 C 13.165875 13.644531 13.034047 13.696328 12.935547 13.798828 L 5.4746094 21.566406 C 6.7566094 20.837406 11.328781 18.249578 15.050781 16.142578 C 15.334781 15.981578 15.386156 15.599281 15.160156 15.363281 L 13.65625 13.798828 C 13.55775 13.696328 13.427875 13.644531 13.296875 13.644531 z' />
    </SvgIcon>
  );
};

export const DemoIcon = () => {
  return (
    <SvgIcon>
      <path d='M 12 2 C 11.448 2 11 2.448 11 3 L 3 3 C 2.448 3 2 3.448 2 4 C 2 4.552 2.448 5 3 5 L 3 15 C 3 16.105 3.895 17 5 17 L 9.5996094 17 L 8.5097656 19.726562 C 8.2657656 20.336562 8.7150938 21 9.3710938 21 C 9.7510937 21 10.091422 20.769016 10.232422 20.416016 L 11.599609 17 L 12.400391 17 L 13.767578 20.416016 C 13.908578 20.769016 14.248906 21 14.628906 21 C 15.284906 21 15.734234 20.336562 15.490234 19.726562 L 14.400391 17 L 19 17 C 20.105 17 21 16.105 21 15 L 21 5 C 21.552 5 22 4.552 22 4 C 22 3.448 21.552 3 21 3 L 13 3 C 13 2.448 12.552 2 12 2 z M 17.292969 7.7070312 C 17.548969 7.7070312 17.805 7.805 18 8 C 18.39 8.39 18.39 9.0240625 18 9.4140625 L 14.707031 12.707031 C 14.317031 13.097031 13.682969 13.097031 13.292969 12.707031 L 11 10.414062 L 8.7070312 12.707031 C 8.3160312 13.098031 7.6829687 13.098031 7.2929688 12.707031 L 6 11.414062 C 5.61 11.024062 5.61 10.39 6 10 C 6.39 9.61 7.0240625 9.61 7.4140625 10 L 8 10.585938 L 10.292969 8.2929688 C 10.683969 7.9019687 11.317031 7.9019688 11.707031 8.2929688 L 14 10.585938 L 16.585938 8 C 16.780938 7.805 17.036969 7.7070312 17.292969 7.7070312 z' />
    </SvgIcon>
  );
};

export const fromRef = (ref) => {
  let icon;
  switch (ref) {
    case 'BITBUCKET':
      icon = <BitbucketIcon />;
      break;
    default:
      console.error(`Unknown Icon ${ref}`)
      icon = <SvgIcon />
      break;
  }
  return icon;
};
